import React from 'react'
import { Helmet } from 'react-helmet'
import {organization, person,  siteUrl} from './seoDefinitions'
import SeoBreadcrumb from './seoBreadcrumb'

// Complete tutorial: https://www.gatsbyjs.org/docs/add-seo-component/

const SeoArticle = ({ title, 
               description, 
               imagePath, 
               articleData,   
               locale, 
               pageContext }) => {


   const url= `${siteUrl}${pageContext?pageContext.breadcrumb.location:''}`
   const image = `${siteUrl}${imagePath}`

  let schemaArticle = null

    schemaArticle = {
      '@context': 'http://schema.org',
      '@type': 'Article', 
      author: person,
      copyrightHolder: organization,
      copyrightYear: '2022',
      creator: organization,
      publisher: organization,
      datePublished: articleData.frontmatter.date,
      dateModified: articleData.frontmatter.date,
      description: description,
      headline: title,
      inLanguage: locale,
      url: url,
      name: title,    
      image: {
        '@type': 'ImageObject',
        url: imagePath,
      },
    }

  return (
    <>
      <Helmet title={title}>
        <html lang={locale} />
        <meta name="description" content={description} />
        <meta name="image" content={image} />
        <meta property="og:url" content={url} />
        <meta property="og:type" content="article" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={image} />        
        <meta property="og:author:first_name" content="Jörg" />      
        <meta property="og:author:last_name" content="Brandeis" />        
        <meta property="og:article:published_time" content={articleData.frontmatter.date} />        
    <meta name="twitter:creator" content={person.name} />
    <meta name="twitter:card" content='summary' />    
    <meta name="twitter:title" content={title} />
    <meta name="twitter:description" content={description} />
    <meta name="twitter:image" content={image} />
    <meta name="twitter:image:alt" content={title} />

        <script type="application/ld+json">{JSON.stringify(schemaArticle)}</script>
      </Helmet>
      {/*<SeoTwitter title={title} image={image} desc={description} username={person.name} />*/}
      <SeoBreadcrumb pageContext={pageContext}
                     title={title}/>
    </>
  )
}

export default SeoArticle

