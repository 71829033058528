import * as React from 'react'
import { MDXRenderer } from 'gatsby-plugin-mdx'

import Layout from '../layout.js'
import SeoArticle from '../seoArticle'
import NewsSlider from '../newsSlider'

const BlogPost = ({ pageContext }) => {
  const data = pageContext.node
  
  var translationContext = { locale: pageContext.locale,
                             defaultMenu: false,
                             translations: data.frontmatter.translations };

  return (
    <div class="main-padding">
      <SeoArticle title={data.frontmatter.title}
        description={data.excerpt}
        imagePath={data.frontmatter.hero_image ?
          data.frontmatter.hero_image.childImageSharp.gatsbyImageData.images.fallback.src : ''}
        articleData={data}
        locale={pageContext.locale}
        pageContext={pageContext} />

      <Layout title={data.frontmatter.title}
        pageContext={pageContext}
        translationContext={translationContext}>

        <div class="blogpost">
              <NewsSlider size="blog-full"></NewsSlider>
          <MDXRenderer>
            {data.body}
          </MDXRenderer>
        </div>
      </Layout>
    </div>)
}

export default BlogPost